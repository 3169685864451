import React, { useState } from 'react';
import { Stack, Button, Nav } from 'react-bootstrap';
import { BsArrowLeft } from 'react-icons/bs';
import MeasurementsDisplay from '../../widgets/measurements-display';
import StatesDisplay from '../../widgets/states-display';
import AddState from '../../forms/add-state';
import AddMeasurementModal from '../../forms/add-measurement-modal';
import { useUserContext } from '../../../contexts/user-context';

interface SourceChildrenManagerProps {
  selectedSource: any;
  setSelectedSource: any;
}

function SourceChildrenManager({ selectedSource, setSelectedSource }: SourceChildrenManagerProps) {
  const [activeTab, setActiveTab] = useState('measurements');
  const [showAddMeasurement, setShowAddMeasurement] = useState(false);
  const [showAddState, setShowAddState] = useState(false);
  const { user } = useUserContext();

  const handleAddMeasurement = () => {
    setShowAddMeasurement(true);
  };

  const handleAddState = () => {
    setShowAddState(true);
  };

  const handleMeasurementAdded = () => {
    // Refresh measurements display
    setShowAddMeasurement(false);
  };

  const handleStateAdded = () => {
    // Refresh states display
    setShowAddState(false);
  };

  return (
    <div className="SourceChildrenManager__Page">
      <Stack direction="horizontal" gap={3} border-radius="10px">
        <div className="lgHeader" style={{ fontSize: '32px' }}>
          {selectedSource.name}
        </div>

      </Stack>

      <div className="mdHeader" style={{ fontSize: '24px', marginTop: '20px' }}>
        Data Types
      </div>

      <Nav variant="tabs" className="mb-3">
        <Nav.Item>
          <Nav.Link
            active={activeTab === 'measurements'}
            onClick={() => setActiveTab('measurements')}
          >
            Measurements
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            active={activeTab === 'states'}
            onClick={() => setActiveTab('states')}
          >
            States
          </Nav.Link>
        </Nav.Item>
      </Nav>

      {activeTab === 'measurements' ? (
        <>
          <MeasurementsDisplay
            selectedSource={selectedSource}
            setSelectedSource={setSelectedSource}
            user={user}
          />
          
          <AddMeasurementModal
            show={showAddMeasurement}
            handleClose={() => setShowAddMeasurement(false)}
            selectedSource={selectedSource}
            user={user}
            onMeasurementAdded={handleMeasurementAdded}
          />
        </>
      ) : (
        <>
          <StatesDisplay
            selectedSource={selectedSource}
            setSelectedSource={setSelectedSource}
          />

          <AddState
            show={showAddState}
            handleClose={() => setShowAddState(false)}
            selectedSource={selectedSource}
            user={user}
            onStateAdded={handleStateAdded}
          />
        </>
      )}

      <div className="ms-auto">
        <Button variant="outline-primary" onClick={() => setSelectedSource(null)}>
          <BsArrowLeft /> Back to Sources
        </Button>
      </div>
    </div>
  );
}

export default SourceChildrenManager;
