import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { submitSource } from '../../controllers/data';
import { useUserContext } from '../../contexts/user-context';

interface AddSourceModalProps {
  show: boolean;
  handleClose: () => void;
  selectedCollection: any;
  onSourceAdded: () => void;
}

function AddSourceModal({ show, handleClose, selectedCollection, onSourceAdded }: AddSourceModalProps) {
  const [name, setName] = useState('');
  const [info, setInfo] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useUserContext();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await submitSource(
        {
          collectionId: selectedCollection.collectionId,
          name,
          info
        },
        user,
        () => {
          handleClose();
          onSourceAdded();
          setName('');
          setInfo('');
        }
      );
    } catch (error) {
      console.error('Error submitting source:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>New Source</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-muted mb-4">
          Add a new data source to track information from a specific device, location, or system. Sources help organize your measurements and states by their origin, making it easier to maintain data provenance.
        </p>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Source Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="e.g., Lab Sensor 1, Production Line A, Health Monitor"
              required
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={info}
              onChange={(e) => setInfo(e.target.value)}
              placeholder="Add any additional notes or context about this source"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? 'Adding...' : 'Add Source'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddSourceModal;
