import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import EmailInput from "../components/inputs/email";
import PasswordInput from "../components/inputs/password";
import GeneralSubmitButton from "../components/buttons/general-submit";
import { loginUser, checkExistingSession } from "../controllers/users.js";
import { loginFormDataProps } from "../components/data-types";
import { isLoginFormDataValid } from "../utils/validations";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../contexts/user-context";

function HeaderComponent() {
  return (
    <div style={{ textAlign: "center", marginBottom: "1.5cm"}}>
      <h1>Oasis-Dashboard</h1>
    </div>
  )
}

function FooterComponent() {
  const navigate = useNavigate()
  return (
    <div style={{ textAlign: "center", marginTop: "1cm"}}>
      
      <p>Don't have an account?</p>
          
          <Button variant = "outline-success" onClick={() => navigate("/register")}>
            Register
          </Button>
    
    </div>
  )
}

const LoginPage = () => {
  const { setUser } = useUserContext();
  const navigate = useNavigate();
  const [validated, setValidated] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [status, setStatus] = useState<string>("danger");
  
  useEffect(() => {
    // Check for existing session on component mount
    (async () => {
      const hasSession = await checkExistingSession(setUser);
      if (hasSession) {
        navigate('/');
      }
    })();
  }, [setUser, navigate]);
  
  const [formData, setFormData] = useState<loginFormDataProps>({
    email: "",
    password: "",
    remainLoggedIn: false,
  });
  
  function setFieldValue(key: string, value: any): void {
    setFormData((existingValues) => ({
      ...existingValues,
      [key]: value,
    }));
  }
  
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    setFieldValue("remainLoggedIn", !formData.remainLoggedIn);
  };

  const afterLoginClicked = () => {
    if (isLoginFormDataValid(formData)) {
      loginUser(formData, setMessage, setUser, setStatus, setValidated);
    }
  };
  
  return (
    <div>
      <Alert
        variant = {status}
        style={{
          visibility: message ? "visible" : "hidden",
        }}
      >
      {" "}
      {message}{" "}
      </Alert>


      <Container className="auto-centered">
        <Form noValidate validated={validated}>

         <HeaderComponent></HeaderComponent>
          
          <Container
            style={{
              border: "1px solid rgba(0, 0, 0, 0.1)",
              borderRadius: "5px",
              paddingTop: "1em",
              paddingLeft: "2.5em",
              paddingRight: "2.5em",
              paddingBottom: "1.5em",
              display: "inline-block",
            }}
          >
            <Row>
              <Col>
                <EmailInput
                  id="login-page-email-input"
                  label="Email"
                  fieldName="email"
                  value={formData.email}
                  required={true}
                  placeholder="your email"
                  setFieldValue={setFieldValue}
                  colWidth={12}
                  readOnly={false}
                  invalidMessage="Please enter an email address"
                  style={{ 
                    fontSize: '18px',
                    fontWeight: 'normal'
                   }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col>
                <PasswordInput
                  id="login-page-password-input"
                  label="Password"
                  fieldName="password"
                  placeholder="your password"
                  value={formData.password}
                  required={true}
                  setFieldValue={setFieldValue}
                  colWidth={12}
                  readOnly={false}
                  invalidMessage="Please enter a password"
                  style={{ 
                    fontSize: '18px',
                    fontWeight: 'normal'
                   }}
                />
              </Col>
            </Row>
            <br></br>
            <Row className="mb-3">
              <Col>
                <Form.Check 
                  type="checkbox" 
                  id="remember-me"
                  label="Remember me" 
                  checked={isChecked} 
                  onChange={handleCheckboxChange} 
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col>
                <GeneralSubmitButton
                  label="Login"
                  onClick={() => afterLoginClicked()}
                />
              </Col>
            </Row>
            <br></br>
          </Container>

          <FooterComponent></FooterComponent>
          
        </Form>
      </Container>
    </div>
  );
};

export default LoginPage;
