import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { submitCollection } from '../../controllers/data';
import { useUserContext } from '../../contexts/user-context';

interface AddCollectionModalProps {
  show: boolean;
  handleClose: () => void;
  onCollectionAdded: () => void;
}

function AddCollectionModal({ show, handleClose, onCollectionAdded }: AddCollectionModalProps) {
  const [name, setName] = useState('');
  const [info, setInfo] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useUserContext();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await submitCollection(
        {
          name,
          info
        },
        user,
        () => {
          handleClose();
          onCollectionAdded();
          setName('');
          setInfo('');
        }
      );
    } catch (error) {
      console.error('Error submitting collection:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>New Collection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-muted mb-4">
          Create a new collection to organize related data sources together. Collections help you group and manage data from similar projects, experiments, or monitoring systems in one place.
        </p>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Collection Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="e.g., Lab Equipment, Health Monitoring, Production Line"
              required
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={info}
              onChange={(e) => setInfo(e.target.value)}
              placeholder="Add any additional notes or context about this collection"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? 'Adding...' : 'Add Collection'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddCollectionModal;
