import { AgGridReact } from "ag-grid-react";
import "../../../node_modules/ag-grid-community/styles/ag-grid.css";
import "../../../node_modules/ag-grid-community/styles/ag-theme-alpine.css";
import { measurementProps } from "../data-types";
import { MeasurementsDisplayProps } from "../component-types";
import { getAndSetSourceMeasurements } from "../../controllers/data";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import Button from "react-bootstrap/Button";
import { useState, useEffect } from "react";
import { useUserContext } from "../../contexts/user-context";
import Plot from "react-plotly.js";
import { BsInfoCircle, BsChevronDown, BsChevronUp } from "react-icons/bs";
import Alert from "react-bootstrap/Alert";
import AddMeasurementModal from '../forms/add-measurement-modal';

function MeasurementsDisplay({ selectedSource, setSelectedSource, user }: MeasurementsDisplayProps) {
  const [sourceMeasurements, setSourceMeasurements] = useState<Array<measurementProps> | []>([]);
  const [measurementMap, setMeasurementMap] = useState<Map<string, measurementProps[]>>(new Map());
  const [activeMeasurements, setActiveMeasurements] = useState<Set<string>>(new Set());
  const [showMeasurementsInfo, setShowMeasurementsInfo] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAddMeasurement, setShowAddMeasurement] = useState(false);

  useEffect(() => {
    if (selectedSource?.sourceId) {
      setIsLoading(true);
      console.log('Fetching measurements for source:', selectedSource.sourceId);
      getAndSetSourceMeasurements(
        user,
        selectedSource.sourceId,
        setSourceMeasurements,
        () => {
          console.log('Measurements fetch callback');
          setIsLoading(false);
        },
      );
    }
  }, [selectedSource]);

  useEffect(() => {
    // Group measurements by name
    const newMap = new Map();
    sourceMeasurements.forEach((measurement) => {
      const measurements = newMap.get(measurement.name) || [];
      measurements.push(measurement);
      newMap.set(measurement.name, measurements);
    });
    setMeasurementMap(newMap);
  }, [sourceMeasurements]);

  function toggleMeasurement(measurement: ICellRendererParams<any, any, any>) {
    const name: string = measurement.node.data.name;
    setActiveMeasurements(prev => {
      const newSet = new Set(prev);
      if (newSet.has(name)) {
        newSet.delete(name);
      } else {
        newSet.add(name);
      }
      return newSet;
    });
  }

  function ToggleMeasurementButton(props: ICellRendererParams) {
    const name: string = props.node.data.name;
    const isActive = activeMeasurements.has(name);
    return (
      <Button 
        variant={isActive ? "primary" : "primary"} 
        onClick={() => toggleMeasurement(props)}
        style={{
          borderRadius: "20px",
          width: "150px",
          height: "38px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {isActive ? "Remove" : "Add"}
      </Button>
    );
  }

  const downloadCSV = () => {
    const activeData = Array.from(activeMeasurements).flatMap(name => 
      measurementMap.get(name) || []
    );
    
    const csvContent = [
      ["Name", "Value", "Unit", "Created At", "Info"].join(","),
      ...activeData.map(m => 
        [m.name, m.value, m.unit, m.createdAt, m.info].map(val => `"${val}"`).join(",")
      )
    ].join("\n");

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'measurements.csv';
    link.click();
  };

  const getMostRecentMeasurements = (measurements: measurementProps[]) => {
    const mostRecentMap = new Map();
  
    measurements.forEach((measurement: measurementProps) => {
      const existing = mostRecentMap.get(measurement.name);
      if (!existing || new Date(measurement.createdAt) > new Date(existing.createdAt)) {
        mostRecentMap.set(measurement.name, measurement);
      }
    });
  
    return Array.from(mostRecentMap.values());
  };

  const mostRecentMeasurements = getMostRecentMeasurements(sourceMeasurements);

  // Color palette for the graph lines and markers
  const colors = [
    'rgba(0,155,92,255)',
    'rgba(22,95,89,255)',
    'rgba(1,156,92,255)',
    'rgba(65,204,176,255)',
    'rgb(68,204,52)',
    'rgba(1,209,48,255)',
    'rgba(185,185,185,255)',
    'rgba(4,4,4,255)',
    'rgba(90,93,90,255)',
    'rgba(124,125,125,255)'
  ];

  // Calculate the grid height
  const rowHeight = 65; // Increased from 50 to add more padding
  const gridHeight = mostRecentMeasurements.length * rowHeight;

  // Column Definitions: Defines the columns to be displayed.
  const columnDefs: ColDef[] = [
    {
      flex: 1,
      headerName: "Name",
      field: "name",
      wrapText: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        whiteSpace: "normal",
        lineHeight: "1.5em",
        padding: "10px 0"
      }),
    },
    {
      flex: 1,
      headerName: "Value",
      field: "value",
      wrapText: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        whiteSpace: "normal",
        lineHeight: "1.5em",
        padding: "10px 0"
      }),
    },
    {
      flex: 1,
      headerName: "Unit",
      field: "unit",
      wrapText: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        whiteSpace: "normal",
        lineHeight: "1.5em",
        padding: "10px 0"
      }),
    },
    {
      flex: 1.5,
      headerName: "Description",
      field: "info",
      wrapText: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        whiteSpace: "normal",
        lineHeight: "1.5em",
        padding: "10px 0"
      }),
    },
    {
      flex: 1,
      headerName: "Created",
      field: "createdAt",
      wrapText: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        whiteSpace: "normal",
        lineHeight: "1.5em",
        padding: "10px 0"
      }),
    },
    {
      flex: 1,
      headerName: "Updated",
      field: "updatedAt",
      wrapText: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        whiteSpace: "normal",
        lineHeight: "1.5em",
        padding: "10px 0"
      }), 
    },
    {
      flex: 0.75,
      headerName: "Actions",
      field: "action",
      cellRenderer: ToggleMeasurementButton,
      wrapText: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 0"
      }),
    },
  ];

  const handleMeasurementAdded = () => {
    setShowAddMeasurement(false);
    setIsLoading(true);
    getAndSetSourceMeasurements(
      user,
      selectedSource.sourceId,
      setSourceMeasurements,
      () => {
        console.log('Measurements fetch callback');
        setIsLoading(false);
      },
    );
  };

  return (
    <div className="p-3">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4>Measurements</h4>
        <div className="d-flex align-items-center">
          <Button 
            variant="link" 
            onClick={() => setShowMeasurementsInfo(!showMeasurementsInfo)}
            className="p-0 me-3"
          >
            <BsInfoCircle size={20} />
            {showMeasurementsInfo ? <BsChevronUp className="ms-1" /> : <BsChevronDown className="ms-1" />}
          </Button>
          <Button 
            variant="primary" 
            onClick={() => setShowAddMeasurement(true)}
            className="me-2"
          >
            Add New Measurement
          </Button>
          <Button 
            variant="primary" 
            onClick={downloadCSV}
            disabled={activeMeasurements.size === 0}
          >
            Download CSV
          </Button>
        </div>
      </div>

      {showMeasurementsInfo && (
        <Alert variant="info" className="mb-3" style={{ background: 'rgba(207, 244, 252, 0.4)' }}>
          Select measurements to display their history in the grid below. The grid will show all entries for the selected measurements ordered by time.
        </Alert>
      )}

      <AddMeasurementModal
        show={showAddMeasurement}
        handleClose={() => setShowAddMeasurement(false)}
        selectedSource={selectedSource}
        onMeasurementAdded={handleMeasurementAdded}
        user={user}
      />

      {activeMeasurements.size > 0 && (
        <div 
          className="ag-theme-alpine mb-4" 
          style={{ 
            height: '500px',
            width: '100%',
            border: '1.5px solid rgba(124,125,125,255)',
            borderRadius: '10px',
            overflow: 'hidden'
          }}
        >
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="text-center">
                <div className="spinner-border text-primary mb-2" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <div>Loading measurements...</div>
              </div>
            </div>
          ) : (
            <Plot
              data={Array.from(activeMeasurements).map((name, index) => {
                const measurements = measurementMap.get(name) || [];
                return {
                  name: name,
                  x: measurements.map(m => m.createdAt),
                  y: measurements.map(m => m.value ?? null).filter((v): v is number => v !== null),
                  type: "scatter",
                  mode: "lines+markers",
                  line: { color: colors[index % colors.length] },
                  marker: { color: colors[index % colors.length] }
                };
              })}
              layout={{
                title: {
                  text: "Measurements Over Time",
                  font: {
                    size: 24,
                    weight: 24,
                  },
                },
                autosize: true,
                margin: { l: 50, r: 50, t: 80, b: 50 },
                width: undefined,
                height: undefined,
                showlegend: true,
                legend: {
                  x: 1,
                  xanchor: 'right',
                  y: 1
                }
              }}
              useResizeHandler={true}
              style={{ 
                width: "100%",
                height: "100%",
              }}
              config={{
                displaylogo: false,
                responsive: true,
                toImageButtonOptions: {
                  format: 'png',
                  filename: 'measurements_graph',
                  height: 800,
                  width: 1200,
                  scale: 2
                },
                modeBarButtons: [
                  ['toImage'],
                  ['zoom2d', 'pan2d'],
                  ['zoomIn2d', 'zoomOut2d', 'autoScale2d', 'resetScale2d'],
                ]
              }}
            />
          )}
        </div>
      )}

      <div 
        className="ag-theme-alpine" 
        style={{ 
          height: '300px',
          width: '100%',
          overflow: 'hidden'
        }}
      >
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-center">
              <div className="spinner-border text-primary mb-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div>Loading measurements...</div>
            </div>
          </div>
        ) : (
          <AgGridReact
            rowData={mostRecentMeasurements}
            columnDefs={columnDefs}
            rowHeight={rowHeight}
            defaultColDef={{
              resizable: true,
              sortable: true,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default MeasurementsDisplay;
