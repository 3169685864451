import React from "react";
import Container from "react-bootstrap/Container";
import {clearSession } from "../../controllers/users.js";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import logo from "../../images/oasis-logo.png";
import { useNavigate } from "react-router-dom";
import { AppNavBarProps } from "../component-types";
import { useUserContext } from "../../contexts/user-context";

function AppNavBar({}: AppNavBarProps) {
  const navigate = useNavigate();
  const { user, setUser } = useUserContext();
  const loggedIn = user && user.userId && user.idToken;

  const handleLogout = () => {
    setUser({ userId: "", idToken: "" });
    clearSession();
    navigate("/login");
  };

  return (
    <Navbar
      style={{
        fontSize: "18px",
      }}
      bg="light"
      expand="lg"
    >
      <Container fluid>
        <Navbar.Brand
          style={{
            marginLeft: "1em",
            cursor: "pointer"
          }}
          onClick={() => navigate("/")}
        >
          <img
            alt=""
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{" "}
          {loggedIn ? "Oasis-X" : "O-X"}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          {loggedIn && (
            <Button
              variant="primary"
              onClick={handleLogout}
              style={{ marginRight: "1em" }}
            >
              Log Out
            </Button>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AppNavBar;
