import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import LoginPage from "./pages/login";
import RegisterPage from "./pages/register";
import HomePage from "./pages/home";
import { useUserContext } from "./contexts/user-context";
import { useState, useEffect } from 'react';
//import { useInitialization } from './hooks/initialization';
import { checkExistingSession } from "./controllers/users";
//AG Grid uprade from 30.0.2
import { AllCommunityModule, ModuleRegistry, provideGlobalGridOptions } from 'ag-grid-community';
// Register all community features
ModuleRegistry.registerModules([AllCommunityModule]);
// Mark all grids as using legacy themes
provideGlobalGridOptions({ theme: "legacy"});

function App() {
  const { user, setUser } = useUserContext();
  const [isValidatingSession, setIsValidatingSession] = useState(true);

  useEffect(() => {
    const validateSession = async () => {
      setIsValidatingSession(true);
      const isValid = await checkExistingSession(setUser);
      setIsValidatingSession(false);
    };
    validateSession();
  }, [setUser]);

  const loggedIn = user && user.userId && user.idToken;

  if (isValidatingSession) {
    return <div>Loading...</div>; // Or your loading component
  }

  return (
    <Routes>
      <Route
        path="/"
        element={loggedIn ? <HomePage /> : <Navigate to={"login"} replace />}
      />
      <Route
        path="/login"
        element={loggedIn ? <Navigate to={"/"} replace /> : <LoginPage />}
      />
      <Route
        path="/register"
        element={loggedIn ? <Navigate to={"/"} replace /> : <RegisterPage />}
      />
      <Route
        path="*"
        element={<Navigate to={loggedIn ? "/" : "login"} replace />}
      />
    </Routes>
  );
}

export default App;
