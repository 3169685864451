import { AgGridReact } from "ag-grid-react";
import "../../../node_modules/ag-grid-community/styles/ag-grid.css";
import "../../../node_modules/ag-grid-community/styles/ag-theme-alpine.css";
import { stateProps } from "../data-types";
import { StatesDisplayProps } from "../component-types";
import { getAndSetSourceStates } from "../../controllers/data";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import Button from "react-bootstrap/Button";
import { useState, useEffect } from "react";
import { useUserContext } from "../../contexts/user-context";
import { BsInfoCircle, BsChevronDown, BsChevronUp } from "react-icons/bs";
import Alert from "react-bootstrap/Alert";
import AddState from '../forms/add-state';

const COLORS = [
  'rgba(0,155,92,0.15)',
  'rgba(22,95,89,0.15)',
  'rgba(1,156,92,0.15)',
  'rgba(65,204,176,0.15)',
  'rgba(68,204,52,0.15)',
  'rgba(1,209,48,0.15)',
  'rgba(185,185,185,0.15)',
  'rgba(4,4,4,0.15)',
  'rgba(90,93,90,0.15)',
  'rgba(124,125,125,0.15)'
];

function StatesDisplay({ selectedSource, setSelectedSource }: StatesDisplayProps) {
  const [sourceStates, setSourceStates] = useState<Array<stateProps> | []>([]);
  const [showAddState, setShowAddState] = useState(false);
  const [stateMap, setStateMap] = useState<Map<string, stateProps[]>>(new Map());
  const [activeStates, setActiveStates] = useState<Set<string>>(new Set());
  const [showStatesInfo, setShowStatesInfo] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useUserContext();

  useEffect(() => {
    if (selectedSource?.sourceId) {
      setIsLoading(true);
      console.log('Fetching states for source:', selectedSource.sourceId);
      getAndSetSourceStates(
        user,
        selectedSource.sourceId,
        setSourceStates,
        () => {
          console.log('States fetch callback');
          setIsLoading(false);
        },
      );
    }
  }, [selectedSource]);

  useEffect(() => {
    console.log('Source states updated:', sourceStates);
    // Group states by name
    const newMap = new Map();
    sourceStates.forEach((state) => {
      if (state && state.name) {  
        const states = newMap.get(state.name) || [];
        states.push(state);
        newMap.set(state.name, states);
      }
    });
    setStateMap(newMap);
  }, [sourceStates]);

  function toggleState(state: ICellRendererParams<any, any, any>) {
    const name: string = state.node.data.name;
    setActiveStates(prev => {
      const newSet = new Set(prev);
      if (newSet.has(name)) {
        newSet.delete(name);
      } else {
        newSet.add(name);
      }
      return newSet;
    });
  }

  function ToggleStateButton(props: ICellRendererParams) {
    const name: string = props.node.data.name;
    const isActive = activeStates.has(name);
    return (
      <Button 
        variant={isActive ? "primary" : "primary"} 
        onClick={() => toggleState(props)}
        style={{
          borderRadius: "20px",
          width: "150px",
          height: "38px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {isActive ? "Remove" : "Add"}
      </Button>
    );
  }

  const downloadCSV = () => {
    const activeData = Array.from(activeStates).flatMap(name => 
      stateMap.get(name) || []
    );
    
    const csvContent = [
      ["Name", "Value", "Created At", "Info"].join(","),
      ...activeData.map(s => 
        [s.name, s.value, s.createdAt, s.info].map(val => `"${val}"`).join(",")
      )
    ].join("\n");

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'states.csv';
    link.click();
  };

  // Define the columns for the unique states menu grid
  const uniqueStatesColumnDefs: ColDef[] = [
    { 
      field: "name",
      headerName: "State Name",
      flex: 1,
      sortable: true,
      filter: true
    },
    { 
      field: "value",
      headerName: "Latest Value",
      flex: 1,
      sortable: true,
      filter: true
    },
    {
      headerName: "Actions",
      cellRenderer: ToggleStateButton,
      width: 180,
      sortable: false,
      filter: false
    }
  ];

  // Get the most recent state for each unique state name
  const uniqueStates = Array.from(stateMap.entries()).map(([name, states]) => {
    console.log('Processing states for name:', name, states);
    const sortedStates = [...states].sort((a, b) => 
      new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    );
    return sortedStates[0];
  }).filter(Boolean); 

  console.log('Unique states to display:', uniqueStates);

  // Define the columns for the active states grid
  const activeStatesColumnDefs: ColDef[] = [
    { 
      field: "name",
      headerName: "State Name",
      flex: 1,
      cellStyle: (params) => {
        const colorIndex = Array.from(activeStates).indexOf(params.data.name) % COLORS.length;
        return { backgroundColor: COLORS[colorIndex] };
      }
    },
    { 
      field: "value",
      headerName: "Value",
      flex: 1,
      cellStyle: (params) => {
        const colorIndex = Array.from(activeStates).indexOf(params.data.name) % COLORS.length;
        return { backgroundColor: COLORS[colorIndex] };
      }
    },
    { 
      field: "updatedAt",
      headerName: "Updated At",
      flex: 1,
      valueFormatter: (params) => new Date(params.value).toLocaleString(),
      cellStyle: (params) => {
        const colorIndex = Array.from(activeStates).indexOf(params.data.name) % COLORS.length;
        return { backgroundColor: COLORS[colorIndex] };
      }
    },
    { 
      field: "info",
      headerName: "Info",
      flex: 1,
      cellStyle: (params) => {
        const colorIndex = Array.from(activeStates).indexOf(params.data.name) % COLORS.length;
        return { backgroundColor: COLORS[colorIndex] };
      }
    }
  ];

  // Get all states that are currently active
  const activeStateData = Array.from(activeStates).flatMap(name => 
    stateMap.get(name) || []
  ).sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

  return (
    <div className="p-3">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4>States</h4>
        <div className="d-flex align-items-center">
          <Button 
            variant="link" 
            onClick={() => setShowStatesInfo(!showStatesInfo)}
            className="p-0 me-3"
          >
            <BsInfoCircle size={20} />
            {showStatesInfo ? <BsChevronUp className="ms-1" /> : <BsChevronDown className="ms-1" />}
          </Button>
          <Button 
            variant="primary" 
            onClick={() => setShowAddState(true)}
            className="me-2"
          >
            Add New State
          </Button>
          <Button 
            variant="primary" 
            onClick={downloadCSV}
            disabled={activeStates.size === 0}
          >
            Download CSV
          </Button>
        </div>
      </div>

      {showStatesInfo && (
        <Alert variant="info" className="mb-3" style={{ background: 'rgba(207, 244, 252, 0.4)' }}>
          Select states to display their history in the grid below. The grid will show all entries for the selected states ordered by time.
        </Alert>
      )}

      <AddState
        show={showAddState}
        handleClose={() => setShowAddState(false)}
        selectedSource={selectedSource}
        user={user}
        onStateAdded={() => {
          setShowAddState(false);
          setIsLoading(true);
          getAndSetSourceStates(
            user,
            selectedSource.sourceId,
            setSourceStates,
            () => {
              console.log('States fetch callback');
              setIsLoading(false);
            },
          );
        }}
      />

      {activeStates.size > 0 && (
        <div 
          className="ag-theme-alpine mb-4" 
          style={{ 
            height: '500px',
            width: '100%',
            border: '1.5px solid rgba(124,125,125,255)',
            borderRadius: '10px',
            overflow: 'hidden'
          }}
        >
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="text-center">
                <div className="spinner-border text-primary mb-2" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <div>Loading states...</div>
              </div>
            </div>
          ) : (
            <AgGridReact
              rowData={activeStateData}
              columnDefs={activeStatesColumnDefs}
              defaultColDef={{
                resizable: true,
                sortable: true,
                filter: true
              }}
              onGridReady={(params) => {
                params.api.sizeColumnsToFit();
              }}
              onGridSizeChanged={(params) => {
                params.api.sizeColumnsToFit();
              }}
            />
          )}
        </div>
      )}

      <div 
        className="ag-theme-alpine" 
        style={{ 
          height: '300px',
          width: '100%',
          overflow: 'hidden'
        }}
      >
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-center">
              <div className="spinner-border text-primary mb-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div>Loading states...</div>
            </div>
          </div>
        ) : (
          <AgGridReact
            rowData={uniqueStates}
            columnDefs={uniqueStatesColumnDefs}
            defaultColDef={{
              resizable: true,
              sortable: true,
              filter: true
            }}
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
            onGridSizeChanged={(params) => {
              params.api.sizeColumnsToFit();
            }}
          />
        )}
      </div>
    </div>
  );
}

export default StatesDisplay;
