import { AgGridReact } from "ag-grid-react";
import "../../../node_modules/ag-grid-community/styles/ag-grid.css";
import "../../../node_modules/ag-grid-community/styles/ag-theme-alpine.css";
import { CollectionsGridProps } from "../component-types";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import Button from "react-bootstrap/Button";


function CollectionsGrid({ userCollections, setSelectedCollection, setIsLoading}: CollectionsGridProps) {
  
    function openCollection(collection: ICellRendererParams<any, any, any>){
      //Implement setting collection context and navigation to data sources page here
      setSelectedCollection(collection.node.data);
      //setIsLoading(true)
      //console.log(collection.node.data)
    }
    
    function OpenCollectionButton(props: ICellRendererParams){
      return (
        <Button variant="primary" onClick= {() => openCollection(props)}> 
          Open
        </Button>
      );
    }

  // Column Definitions: Defines the columns to be displayed.
  const columnDefs: ColDef[] = [
    {
      flex: 1,
      headerName: "Name",
      field: 'name',
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      flex: 1.5,
      headerName: 'Description',
      field: 'info',
      wrapText: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      flex: 1,
      headerName: 'Created',
      field: 'createdAt',
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      flex: 1,
      headerName: 'Updated',
      field: 'updatedAt',
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      flex: .5,
      field: 'action',
      headerName: '',
      cellRenderer: OpenCollectionButton,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
  ];

  if (userCollections){
    return (
        // wrapping container with theme & size
        <div
        className="ag-theme-alpine" // applying the Data Grid theme
        style={{
          height: 500
        }}
        >
          <AgGridReact //grid data and instance specs are declared here
              rowData={userCollections}
              columnDefs={columnDefs}
              rowHeight={50}
          />
        </div>
    );
  } else {
    return (
      <div>
       <h1> No collections to display</h1>;
      </div>
    )
  }
}

export default CollectionsGrid;