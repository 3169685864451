import { useState } from "react";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { BsInfoCircle, BsChevronDown, BsChevronUp } from "react-icons/bs";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { SourceManagerProps } from "../../component-types";
import SourcesGrid from "../../widgets/sources-grid";
import AddSourceModal from "../../forms/add-source-modal";

function SourceManager({
  selectedCollection,
  setSelectedCollection,
  setSelectedSource
}: SourceManagerProps) {
  const [showAddSource, setShowAddSource] = useState<boolean>(false);
  const [showSourcesInfo, setShowSourcesInfo] = useState<boolean>(true);

  const refreshSources = () => {
    // This is a temporary workaround - ideally we'd refresh just the sources
    if (selectedCollection) {
      setSelectedCollection({ ...selectedCollection });
    }
  };

  return (
    <div className="SourceManager__Page">
      <AddSourceModal
        show={showAddSource}
        handleClose={() => setShowAddSource(false)}
        selectedCollection={selectedCollection}
        onSourceAdded={refreshSources}
      />

      <Stack direction="horizontal" gap={3}>
        <div className="lgHeader" style={{ fontSize: '24px' }}>
          Source Manager
        </div>
        <div className="ms-auto">
          <Button
            variant="primary"
            onClick={() => setShowAddSource(true)}
          >
            Add Source
          </Button>
        </div>
      </Stack>

      <Alert 
        variant="light" 
        className="info-box mb-4"
        style={{
          backgroundColor: 'rgba(248, 249, 250, 0.9)',
          border: '1px solid rgba(0,0,0,0.1)',
          borderRadius: '8px'
        }}
      >
        <div className="d-flex justify-content-between align-items-center" style={{ cursor: 'pointer' }} onClick={() => setShowSourcesInfo(!showSourcesInfo)}>
          <div className="d-flex align-items-center">
            <BsInfoCircle className="me-2" />
            <strong>Data Sources</strong>
          </div>
          {showSourcesInfo ? <BsChevronUp /> : <BsChevronDown />}
        </div>
        {showSourcesInfo && (
          <p className="mb-0 mt-2" style={{ color: '#666' }}>
            Data sources represent individual devices, locations, or systems that generate data. Each source can have its own measurements,
            states, and files, helping you track and organize data from different origins within your collection.
          </p>
        )}
      </Alert>
      
      <div className="medium-padding" />

      <SourcesGrid
        userCollection={selectedCollection}
        setSelectedCollection={setSelectedCollection}
        setSelectedSource={setSelectedSource}
      />
    </div>
  );
}

export default SourceManager;
