import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import AddMeasurementForm from './add-measurement';
import { newMeasurementProps } from '../data-types';

interface AddMeasurementModalProps {
  show: boolean;
  handleClose: () => void;
  selectedSource: any;
  user: any;
  onMeasurementAdded: () => void;
}

const AddMeasurementModal: React.FC<AddMeasurementModalProps> = ({
  show,
  handleClose,
  selectedSource,
  onMeasurementAdded
}) => {
  const [newMeasurement, setNewMeasurement] = useState<newMeasurementProps>({
    name: '',
    sourceId: selectedSource?.id || '',
    value: null,
    unit: '',
    info: null
  });

  const setFieldValue = (field: string, value: any) => {
    setNewMeasurement(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleMeasurementAdded = () => {
    onMeasurementAdded();
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Measurement</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddMeasurementForm
          newMeasurement={newMeasurement}
          setFieldValue={setFieldValue}
          toggleAddingMeasurement={handleMeasurementAdded}
        />
      </Modal.Body>
    </Modal>
  );
};

export default AddMeasurementModal;
