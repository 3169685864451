import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextInput from "../inputs/text";
import Button from "react-bootstrap/Button";
import { submitMeasurement } from "../../controllers/data";
import { MeasurementFormProps } from "../component-types";
import { useUserContext } from "../../contexts/user-context";

function AddMeasurementForm({
  newMeasurement,
  setFieldValue,
  toggleAddingMeasurement,
}: MeasurementFormProps) {
  const { user } = useUserContext();

  const submit = () => {
    return submitMeasurement(newMeasurement, user, toggleAddingMeasurement);
  };

  return (
    <div className="MeasurementForm">
      <div className="measurement-form-container p-4 bg-light rounded border" style={{ backgroundColor: '#f8f9fa' }}>
        <Row className="align-items-start mb-3">
          <Col>
            <h2>New Measurement</h2>
            <p className="text-muted">
              Add a new measurement to track numerical data from your source. Measurements are perfect for tracking quantifiable values like temperature, weight, or any other metric that can be expressed with a number and unit.
            </p>
          </Col>
        </Row>
        
        <div className="measurement-form-fields">
          <Row className="mb-4">
            <TextInput
              id="task-name-input"
              label="Name"
              fieldName="name"
              value={newMeasurement.name}
              setFieldValue={setFieldValue}
              colWidth={6}
              placeholder="e.g., Temperature, Weight, Voltage, Steps, RPM, Pressure"
            />
          </Row>

          <Row className="mb-4">
              <TextInput
                id="task-value-input"
                label="Value"
                fieldName="value"
                value={newMeasurement.value?.toString() || ''}
                setFieldValue={(fieldName, value) => setFieldValue(fieldName, parseFloat(value) || 0)}
                colWidth={12}
                placeholder="Enter the numerical value"
              />
            </Row>

            <Row className="mb-4">
              <TextInput
                id="task-unit-input"
                label="Units"
                fieldName="unit"
                value={newMeasurement.unit}
                setFieldValue={setFieldValue}
                colWidth={12}
                placeholder="e.g., °C, kg, V, count, rpm, psi"
              />
            </Row>

          <Row className="mb-4">
            <TextInput
              id="task-info-input"
              label="Additional Information"
              fieldName="info"
              value={newMeasurement.info}
              setFieldValue={setFieldValue}
              colWidth={12}
              placeholder="Add any additional notes or context about this measurement"
            />
          </Row>

          <Row>
            <Col className="d-flex justify-content-between">
              <Button variant="secondary" onClick={() => toggleAddingMeasurement()}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => submit()}>
                Add Measurement
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default AddMeasurementForm;
