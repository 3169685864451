import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { userProps } from '../data-types';
import { submitState } from '../../controllers/data';

interface AddStateProps {
  show: boolean;
  handleClose: () => void;
  selectedSource: any;
  user: userProps;
  onStateAdded: () => void;
}

function AddState({ show, handleClose, selectedSource, user, onStateAdded }: AddStateProps) {
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [info, setInfo] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await submitState(
        {
          sourceId: selectedSource.sourceId,
          name,
          value,
          info
        },
        user,
        () => {
          handleClose();
          onStateAdded();
          setName('');
          setValue('');
          setInfo('');
        }
      );
    } catch (error) {
      console.error('Error submitting state:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>New State</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-muted mb-4">
          Add a new state to track non-numerical data from your source. States are ideal for tracking status, conditions, or any categorical information that isn't measured in numbers.
        </p>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>State Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="e.g., Status, Mode, Condition, Phase"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Value</Form.Label>
            <Form.Control
              type="text"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder="e.g., Active, Standby, Running, Complete"
              required
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={info}
              onChange={(e) => setInfo(e.target.value)}
              placeholder="Add any additional notes or context about this state"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Adding...' : 'Add State'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddState;
