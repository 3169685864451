import { useState, useEffect } from "react";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { BsInfoCircle, BsChevronDown, BsChevronUp } from "react-icons/bs";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { newCollectionProps } from "../../data-types";
import { CollectionManagerProps } from "../../component-types";
import CollectionsGrid from "../../widgets/collections-grid";
import SourceManager from "./source-manager";
import AddCollectionModal from "../../forms/add-collection-modal";

function CollectionManager({
  userCollections,
  selectedCollection,
  setSelectedCollection,
  refreshCollections,
  setIsLoading
}: CollectionManagerProps) {
  const [showCollectionsInfo, setShowCollectionsInfo] = useState<boolean>(true);
  const [showAddCollection, setShowAddCollection] = useState<boolean>(false);

  return (
    <div className="CollectionsManager__Page">
      <AddCollectionModal
        show={showAddCollection}
        handleClose={() => setShowAddCollection(false)}
        onCollectionAdded={refreshCollections}
      />
      <Stack gap={3}>
        <Stack direction="horizontal" gap={3}>
          <div className="lgHeader" style={{ fontSize: '24px' }}>
            Collection Manager
          </div>
          <div className="ms-auto">
            <Button
              variant="primary"
              onClick={() => setShowAddCollection(true)}
            >
              Add Collection
            </Button>
          </div>
        </Stack>
        {!selectedCollection && (
          <div>
            <Alert 
              variant="light" 
              className="info-box mb-4"
              style={{
                backgroundColor: 'rgba(248, 249, 250, 0.9)',
                border: '1px solid rgba(0,0,0,0.1)',
                borderRadius: '8px'
              }}
            >
              <div className="d-flex justify-content-between align-items-center" style={{ cursor: 'pointer' }} onClick={() => setShowCollectionsInfo(!showCollectionsInfo)}>
                <div className="d-flex align-items-center">
                  <BsInfoCircle className="me-2" />
                  <strong>Collections</strong>
                </div>
                {showCollectionsInfo ? <BsChevronUp /> : <BsChevronDown />}
              </div>
              {showCollectionsInfo && (
                <p className="mb-0 mt-2" style={{ color: '#666' }}>
                  Collections help you organize your data sources into logical groups. They can represent physical locations (home, office), 
                  environments (test, production), or any other meaningful categorization. Each collection can contain multiple data sources 
                  and helps you manage related data streams together.
                </p>
              )}
            </Alert>
            <CollectionsGrid
              userCollections={userCollections}
              setSelectedCollection={setSelectedCollection}
              setIsLoading={setIsLoading}
            />
          </div>
        )
        }
      </Stack>
    </div>
  );
}

export default CollectionManager;
